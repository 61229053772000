import logo from './logo.svg';
import ScriptTag from 'react-script-tag';
import './App.css';

function App() {
  return (
    <div className="App">

      <main className="App-header">
      <ScriptTag type="text/javascript" src="bonsai.js" />
        <p>
          We'll see you very soon.
          <h1>- Anorak Digital</h1>
        </p>
      </main>
    </div>
  );
}

export default App;
